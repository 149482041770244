<template>
  <v-card width="100%">
    <v-container>
      <v-card-title class="pl-1 primary--text">{{ title }}</v-card-title>
      <v-data-table
        :headers="headers"
        :items="detallesAumentoAdiExtra"
        class="elevation-1"
        :loading="isLoading"
        :sort-by="['periodoVigenciaHasta']"
        :sort-desc="true"
      >
        <template v-slot:[`item.tieneIva`]="{ item }">
          <v-icon small color="primary">
            {{ item.tieneIva ? checkIcon : "" }}
          </v-icon>
        </template>
      </v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModalVerDetalle"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import helpersExcel from "@/utils/helpers/importExportExcel";
export default {
  name: "VerVariablesComerciales",
  props: {
    rgVarNombre: {
      type: String,
      required: true
    },
    varValoresList: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    checkIcon: enums.icons.CHECK_OUTLINE,
    excelIcon: enums.icons.EXPORT_EXCEL,
    isLoading: false,
    detallesAumentoAdiExtra: [],
    headers: [
      {
        text: "Período desde",
        value: "periodoVigenciaDesde",
        sortable: false,
        align: "center"
      },
      {
        text: "Período hasta",
        value: "periodoVigenciaHasta",
        sortable: false,
        align: "center"
      },
      {
        text: "Valor",
        value: "rgVarValorString",
        sortable: false,
        align: "end"
      }
    ],
    allowedActions: null
  }),
  created() {
    this.title = this.rgVarNombre;
    this.detallesAumentoAdiExtra = this.varValoresList;
  },
  methods: {
    closeModalVerDetalle() {
      this.$emit("closeModalDetalle");
    }
  }
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>
