<template>
  <v-card>
    <v-container>
      <v-card-title class="p-0">
        <v-row>
          <v-col cols="8">
            <span class="primary--text"> {{ formEditTitle }} </span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="rgVarNombre"
                type="text"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(rgVarNombre, 50),
                    rules.requiredTrim(rgVarNombre),
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model.trim="rgVarDescrip"
                label="Descripción de variable comercial"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(rgVarDescrip, 50),
                    rules.requiredTrim(rgVarDescrip),
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="rgProcSelect"
                :items="rgProcCombo"
                item-text="rgProcDescripGral"
                item-value="id"
                label="Procedimiento"
                outlined
                clearable
                dense
                :disabled="useInReglaCom"
                return-object
                @input="permitirAcumular(rgProcSelect)"
                @change="
                  rgProcSelect
                    ? ((descProcNombre = rgProcSelect.rgProcDescripGral),
                      resetParametro())
                    : (descProcNombre = null)
                "
                :rules="rules.required"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="6" class="py-0 my-0">
              <v-switch
                class="py-0 my-2"
                v-model="rgVarInactivo"
                label="Inactivo"
                dense
                outlined
              ></v-switch>
            </v-col>
            <v-col cols="6" sm="6" md="6" class="py-0 my-0">
              <v-card-actions class="py-0">
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  :disabled="!isFormValid"
                  form="form"
                  color="primary"
                  :loading="btnIsLoading"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row>
            <v-subheader v-if="!rgVarId">
              (*)Se debe guardar los datos para poder cargar valores a la
              variable comercial</v-subheader
            >
          </v-row>
          <v-card-title class="p-0">
            <v-row>
              <v-col cols="6" class="mr-n13">
                <span class="primary--text"> Valores </span>
              </v-col>
              <v-col cols="3">
                <v-btn
                  v-if="this.editVariableItem || this.seeBtnHistorial"
                  @click="openModalHistorialLog()"
                  color="primary"
                >
                  Historial
                </v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn
                  v-if="rgVarId"
                  :loading="btnIsLoading"
                  @click="openMonto()"
                  color="primary"
                >
                  Agregar valor
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <!-- Nueva tabla -->
          <v-card class="mb-4">
            <v-data-table
              :headers="headers"
              item-key="rgVarValorId"
              :items="registroSelected"
              :loading="loading"
              class="elevation-1"
              loading-text="Cargando datos..."
              :sort-by="['periodoVigenciaHasta']"
              :sort-desc="true"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="editMonto(item)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar valor</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="deleteVariable(item)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Eliminar valor</span>
                </v-tooltip>
              </template>
              <!-- <template v-slot:[`item.rgVarValor`]="{ item }">
                {{ $options.filters.number(item.rgVarValor) }}
              </template> -->
              <template v-slot:[`item.importe`]="{ item }">
                {{ item.formattedImporte }}
              </template>
            </v-data-table>
          </v-card>
          <!-- Fin nueva tabla -->

          <v-dialog
            v-if="openCargaMonto"
            v-model="openCargaMonto"
            max-width="40%"
            @keydown.esc="cerrarModalMonto"
            persistent
          >
            <v-card>
              <v-container>
                <v-card-title class="p-0">
                  <span class="primary--text"> {{ titleValor }} </span>
                </v-card-title>
                <v-card class="mx-auto mb-3 pt-0" max-width="100%">
                  <v-row>
                    <v-col cols="4" sm="4" md="4" class="pb-0">
                      <v-text-field
                        v-model="vigenciaDesde"
                        label="Período desde"
                        dense
                        outlined
                        hint="Formato AAAAMM"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        v-mask="'######'"
                        :rules="
                          rules.required.concat(
                            [
                              rules.validDateRange(
                                vigenciaDesde,
                                vigenciaHasta
                              ),
                            ],
                            rules.periodoYyyyMm
                          )
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4" md="4" class="pb-0">
                      <v-text-field
                        v-model="vigenciaHasta"
                        label="Período hasta"
                        dense
                        hint="Formato AAAAMM"
                        outlined
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        v-mask="'######'"
                        :rules="
                          rules.required.concat(
                            [
                              rules.validDateRange(
                                vigenciaDesde,
                                vigenciaHasta
                              ),
                            ],
                            rules.periodoYyyyMm
                          )
                        "
                      ></v-text-field>
                    </v-col>

                    <v-col cols="4" sm="4" md="4" class="pb-0">
                      <v-text-field
                        v-model="rgVarValor"
                        type="number"
                        label="Valor"
                        :rules="
                          rgVarValor || rgVarValor === 0
                            ? rules.requiredAcceptZeroDecimal.concat(
                                rules.maxDigitsDecimalNegative(7, 2)
                              )
                            : ['Campo requerido']
                        "
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined @click="cerrarModalMonto"> Cancelar </v-btn>
                    <v-btn
                      @click="guardarMonto()"
                      :disabled="!isFormValid"
                      form="form"
                      color="primary"
                      :loading="btnIsLoadingMonto || saving"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-container>
            </v-card>
          </v-dialog>
        </v-form>
      </v-card-text>
      <v-dialog
        v-if="openHistorialLogValores"
        v-model="openHistorialLogValores"
        :maxWidth="'65%'"
        @keydown.esc="closeModalHistorialLog"
        persistent
      >
        <LogValorVarReglaComercial
          :rgVarId="this.rgVarIdToHistorial"
          @closeModalLog="closeModalHistorialLog"
        ></LogValorVarReglaComercial
      ></v-dialog>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="set"> Cerrar </v-btn>
      </v-card-actions>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'35%'"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import LogValorVarReglaComercial from "@/components/modules/cuotas/configuracion/LogValorVarReglaComercial.vue";
import { mask } from "vue-the-mask";

export default {
  name: "EditVariablesComerciales",
  components: {
    DeleteDialog,
    CurrencyInput,
    LogValorVarReglaComercial,
  },
  props: {
    editVariableItem: {
      type: Object,
    },
  },
  directives: { mask },
  data: () => ({
    isFormValid: false,
    infoIcon: enums.icons.SNB_INFO,
    formEditTitle: "Editar variable comercial",
    newTitle: "Nueva variable comercial",
    titleDelete: "¿Eliminar valor de variable comercial?",
    titleValor: "Nuevo valor",
    useInReglaCom: false,
    deleteIcon: enums.icons.DELETE,
    addValorVariable: enums.icons.CIRCLE_ADD_ITEM,
    editIcon: enums.icons.EDIT,
    btnIsLoading: false,
    vigenciaDesde: null,
    vigenciaHasta: null,
    rules: rules,
    rgVarIdToHistorial: null,
    rgVarValor: null,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999,
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true,
    },
    rgVarNombre: "",
    rgVarDescrip: "",
    rgProcSelect: null,
    btnIsLoadingMonto: false,
    disabledAcumulableSwitch: false,
    rgVarInactivo: false,
    rgProcCombo: [],
    saving: false,
    seeBtnHistorial: false,
    registroSelected: [],
    loading: false,
    parametros: "",
    descProcNombre: "",
    showDeleteModal: false,
    acumula: false,
    openCargaMonto: false,
    openHistorialLogValores: false,
    rgVarId: null,
    btnIsLoading: false,
    headers: [
      {
        text: "Período desde",
        value: "periodoVigenciaDesde",
        sortable: true,
        align: "center",
      },
      {
        text: "Período hasta",
        value: "periodoVigenciaHasta",
        sortable: true,
        align: "center",
      },
      {
        text: "Valor",
        value: "rgVarValorString",
        sortable: false,
        align: "end",
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
  }),
  filters: {
    number(value) {
      // Formatea el número con separador de miles
      return new Intl.NumberFormat().format(value);
    },
  },
  created() {
    this.procRgSelect();

    setTimeout(() => {
      if (this.editVariableItem) {
        this.setVariableComercial();
      } else {
        this.newVariableComercial();
      }
    }, 200);
  },

  methods: {
    ...mapActions({
      saveVariableReglaComercial: "configuracion/saveVariableReglaComercial",
      getReglasComercialesProc: "configuracion/getReglasComercialesProc",
      saveValorVarReglaComercial: "configuracion/saveValorVarReglaComercial",
      deleteValorVariableReglaComercial:
        "configuracion/deleteValorVariableReglaComercial",
      getValoresVarReglaComercial: "configuracion/getValoresVarReglaComercial",
      setAlert: "user/setAlert",
    }),
    async procRgSelect() {
      const response = await this.getReglasComercialesProc();
      this.rgProcCombo = response;
    },
    resetParametro() {
      this.$refs.parametros?.reset();
    },
    checkPermiteAcumular(array) {
      const seleccionado = array.find((x) => x.id == this.rgProcSelect);
      if (seleccionado) {
        this.permitirAcumular(seleccionado);
      }
    },
    permitirAcumular(item) {
      if (item.rgProcPermitirAcumular === true) {
        this.disabledAcumulableSwitch = false;
      } else {
        this.disabledAcumulableSwitch = true;
        this.rgVarInactivo = false;
      }
    },
    setVariableComercial() {
      this.rgVarId = this.editVariableItem.rgVarId;
      this.rgVarNombre = this.editVariableItem.rgVarNombre;
      this.rgVarDescrip = this.editVariableItem.rgVarDescrip;
      this.useInReglaCom = this.editVariableItem.useInReglaCom;
      // this.rgProcSelect = this.rgProcCombo.find(
      //   x => x.id == this.editVariableItem.rgProcId
      // );
      this.rgProcSelect = this.editVariableItem.rgProcId;
      //this.descProcNombre = this.editVariableItem.rgProcNombre;
      //this.parametros = this.editVariableItem.parametros;

      this.rgVarInactivo = this.editVariableItem.rgVarInactivo;
      this.registroSelected = this.editVariableItem.varValoresList;
      // this.vigenciaDesde = this.registroSelected.periodoVigenciaDesde;
      // this.vigenciaHasta = this.registroSelected.periodoVigenciaHasta;
      // this.rgVarValor = this.registroSelected.rgVarValor;
      //  this.RgVarValorId = this.registroSelected.rgVarValorId;
      // this.obtenerValores();
    },

    async newVariableComercial() {
      this.formEditTitle = this.newTitle;
    },
    async save() {
      this.btnIsLoading = true;
      const data = {
        rgVarId: this.rgVarId ? this.rgVarId : 0,
        rgVarNombre: this.rgVarNombre,
        rgVarDescrip: this.rgVarDescrip,
        rgProcId: this.rgProcSelect.id
          ? this.rgProcSelect.id
          : this.rgProcSelect,
        //parametros: this.parametros,

        rgVarInactivo: this.rgVarInactivo,
      };
      try {
        const response = await this.saveVariableReglaComercial(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.rgVarId = response.data.data;
          this.obtenerValores();
          this.btnIsLoading = false;
          this.seeBtnHistorial = true;
        }
      } catch {
        this.btnIsLoading = false;
      }
    },
    async guardarMonto() {
      if (this.saving) {
        return; // Evitar la ejecución duplicada si la función ya está en curso
      }
      this.saving = true;
      this.btnIsLoadingMonto = true;

      const dataMonto = {
        periodoVigenciaDesde: this.vigenciaDesde,
        periodoVigenciaHasta: this.vigenciaHasta,
        rgVarValor: Number(this.rgVarValor),
        rgVarId: this.rgVarId,
        rgVarValorId: this.rgVarValorId,
      };
      try {
        const response = await this.saveValorVarReglaComercial(dataMonto);

        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.cerrarModalMonto();
          this.obtenerValores();
          this.saving = false;
          this.btnIsLoadingMonto = false;
        }
      } catch {
        this.saving = false;
        this.btnIsLoadingMonto = false;
      }
    },
    deleteVariable(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      const response = await this.deleteValorVariableReglaComercial(
        this.itemToDelete.rgVarValorId
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.openCargaMonto = false;
        this.obtenerValores();
        this.openCargaMonto = false;
      }
    },
    editMonto(item) {
      if (item) {
        this.titleValor = "Editar valor";
        this.openCargaMonto = true;
        this.vigenciaDesde = item.periodoVigenciaDesde;
        this.vigenciaHasta = item.periodoVigenciaHasta;
        this.rgVarValor = item.rgVarValor;
        this.rgVarId = item.rgVarId;
        this.rgVarValorId = item.rgVarValorId;
      } else {
        this.openCargaMonto = true;
      }
    },
    async obtenerValores() {
      const valor = await this.getValoresVarReglaComercial(this.rgVarId);
      this.registroSelected = valor;
    },

    openMonto() {
      this.openCargaMonto = true;
      this.titleValor = "Nuevo valor";
    },
    openModalHistorialLog() {
      if(this.editVariableItem) this.rgVarIdToHistorial = this.editVariableItem.rgVarId;
      else this.rgVarIdToHistorial = this.rgVarId;
      this.openHistorialLogValores = true;
    },
    closeModalHistorialLog() {
      this.openHistorialLogValores = false;
    },
    cerrarModalMonto() {
      this.openCargaMonto = false;
      this.vigenciaDesde = null;
      this.vigenciaHasta = null;
      this.rgVarValor = null;
      this.rgVarValorId = null;
      this.obtenerValores();
    },
    set() {
      this.$emit("closeAndReload");
    },
    setMontos() {
      this.$emit("reloadMontos");
    },
  },
};
</script>

<style scoped>
.repeat input {
  border-color: red;
}
.repeat label {
  color: red;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
