<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />

        <!-- Filtro -->
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="py-1">
            <v-expand-transition mode="out-in">
              <v-form
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Status variables  -->
                  <v-col cols="4" sm="4" md="4" class="pb-0">
                    <v-autocomplete
                      v-model="statusSelected"
                      :items="statusItems"
                      label="Seleccionar estado de variables comerciales"
                      item-text="text"
                      item-value="value"
                      clearable
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" class="pb-0"></v-col>
                  <v-col cols="2" sm="2" md="2" class="pb-0 pt-4" align="end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>

        <!-- Termina el filtro -->
        <v-data-table
          :headers="headers"
          :items="reglasComercialesVariables"
          class="elevation-1"
          :search="search"
          item-key="rgVarId"
          :loading="isLoading"
        >
          <template v-slot:[`item.rgVarInactivo`]="{ item }">
            <v-icon
              v-if="item.rgVarInactivo"
              small
              color="error"
              class="d-flex justify-center"
            >
              {{ iconDeshabilitado }}
            </v-icon>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="openModalVc(item)"
                >
                  {{ seeIcon }}
                </v-icon>
              </template>
              <span>Ver detalle de variable comercial</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar variable comercial</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="16"
                  v-on="on"
                  @click="deleteVariable(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar variable comercial</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditVariablesComerciales
        :editVariableItem="editVariableItem"
        @closeAndReload="closeAndReload"
        @reloadMontos="reloadMontos"
      ></EditVariablesComerciales>
    </v-dialog>

    <v-dialog
      v-if="openModalVerVC"
      v-model="openModalVerVC"
      max-width="40%"
      @keydown.esc="closeModalDetalle"
      persistent
    >
      <VerVariablesComerciales
        :varValoresList="varValoresList"
        :rgVarNombre="rgVarNombre"
        @closeModalDetalle="closeModalDetalle"
      ></VerVariablesComerciales
    ></v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'38%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import VerVariablesComerciales from "@/components/modules/cuotas/configuracion/VerVariablesComerciales.vue";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditVariablesComerciales from "@/components/modules/cuotas/configuracion/EditVariablesComerciales.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import FiltersSelected from "@/components/shared/FiltersSelected";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditVariablesComerciales,
    GoBackBtn,
    FiltersSelected,
    VerVariablesComerciales,
    Ayuda
  },
  name: "VariablesComerciales",
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    seeIcon: enums.icons.SEE,
    iconDeshabilitado: enums.icons.CIRCLE_NONE,
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    search: "",
    isLoading: false,
    filtersApplied: [],
    statusItems: [
      {
        text: "Todos",
        align: "start",
        sortable: false,
        value: 0
      },
      {
        text: "Activos",
        align: "start",
        sortable: false,
        value: 1
      },
      {
        text: "Inactivos",
        align: "start",
        sortable: false,
        value: 2
      }
    ],
    statusSelected: 1,
    useInReglaCom: false,
    isFormValid: false,
    showFilters: true,
    showDeleteModal: false,
    idToDelete: null,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.REGLAS_COMERCIALES,
    routeToGo: "ReglasComerciales",
    title: "Variables comerciales",
    titleDelete: "¿Eliminar variable comercial?",
    rules: rules,
    reglasComercialesVariables: [],
    editVariableItem: {},
    openModalVerVC: false,
    headers: [
      {
        text: "Nombre",
        value: "rgVarNombre",
        sortable: true
      },
      {
        text: "Descripción",
        value: "rgVarDescrip",
        sortable: false
      },
      {
        text: "Inactivo",
        value: "rgVarInactivo",
        sortable: false,
        align: "center"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    rgVarId: null,
    allowedActions: null,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.applyFilters();
  },
  // mounted() {
  //   this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  //   this.setPermisos();
  // },
  methods: {
    ...mapActions({
      getVariablesReglasComerciales:
        "configuracion/getVariablesReglasComerciales",
      deleteVariableReglaComercial:
        "configuracion/deleteVariableReglaComercial",
      setAlert: "user/setAlert"
    }),
    async applyFilters() {
      this.isLoading = true;
      this.statusSelected = this.statusSelected ? this.statusSelected : 0;
      const data = await this.getVariablesReglasComerciales(
        this.statusSelected
      );
      this.reglasComercialesVariables = data;
      this.isLoading = false;
      this.toggleFiltersSelected();
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.statusSelected !== undefined && this.statusSelected !== null) {
        this.filtersApplied.splice(0, 1, {
          key: "statusSelected",
          label: "Variable",
          model: this.statusSelected
        });
      }
    },

    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    deleteVariable(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      const response = await this.deleteVariableReglaComercial(
        this.itemToDelete.rgVarId
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
      }
    },

    openModal(item) {
      this.openModalEdit = true;
      this.editVariableItem = item;
    },
    openModalVc(item) {
      this.rgVarNombre = item.rgVarNombre;
      this.varValoresList = item.varValoresList;
      this.openModalVerVC = true;
    },
    closeModalDetalle() {
      this.openModalVerVC = false;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.applyFilters();
    },
    reloadMontos() {
      this.openModal(this.editVariableItem);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    resetForm() {
      this.statusSelected = null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
