<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Historial de cambios</v-card-title
      >
      <v-data-table
        :headers="headersValoresModifRc"
        :items="valoresModificadosItems"
        class="elevation-1"
        item-key="rgVarValorLogId"
        :loading="isLoading"
      >
        <template v-slot:[`item.valorNuevo`]="{ item }">
          <span style="color: red">
            {{ item.valorNuevo }}
          </span>
        </template>
        <template v-slot:[`header`]="">
          <thead>
            <tr>
              <th colspan="3" style="text-align: center">Datos anteriores</th>
              <th colspan="0" style="text-align: left">|</th>
              <th colspan="2" style="text-align: left">Datos nuevos</th>
              <th colspan="3" style="text-align: left">|</th>
            </tr>
          </thead>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModalLog"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LogValorVarReglaComercial",
  props: {
    rgVarId: {
      type: Number,
    },
  },
  data: () => ({
    valoresModificadosItems: [],
    headersValoresModifRc: [
      {
        text: "Período desde",
        value: "periodoDesdeAnt",
        sortable: false,
      },
      {
        text: "Período hasta",
        value: "periodoHastaAnt",
        sortable: false,
      },
      {
        text: "Valor",
        value: "valorAnt",
        align: "end",
        sortable: false,
      },
      {
        text: "Período desde",
        value: "periodoDesdeNuevo",
        sortable: false,
      },
      {
        text: "Período hasta",
        value: "periodoHastaNuevo",
        sortable: false,
      },
      {
        text: "Valor",
        value: "valorNuevo",
        align: "end",
        sortable: false,
      },
      {
        text: "Acción",
        value: "accion",
        sortable: false,
      },
      {
        text: "Usuario",
        value: "usuario",
        sortable: false,
      },
      {
        text: "Fecha",
        value: "fecha",
        align: "center",
        sortable: false,
      },
    ],
    isLoading: false,
  }),
  created() {
    this.setLogsValoresRg();
  },
  methods: {
    ...mapActions({
      getLogsValoresModifByVariable:
        "configuracion/getLogsValoresModifByVariable",
    }),
    async setLogsValoresRg() {
      this.isLoading = true;
      const response = await this.getLogsValoresModifByVariable(this.rgVarId);
      this.valoresModificadosItems = response;
      this.isLoading = false;
    },
    closeModalLog() {
      this.$emit("closeModalLog");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>